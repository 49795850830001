<template>
	<div class="single-chef background-aili-purple">
		<Header />
		<div class="content">
			<MainColumn v-if="author">
				<p class="top-title color-aili-white font-size-50">Articoli</p>
				<CategoryHeaderArticles v-if="articleCategories.length > 0" />

				<div class="content-shadow">
					<div class="panel background-aili-purple-light">
						<div class="header-expert">
							<h4 class="color-aili-purple font-size-35">
								{{ author.first_name + ' ' + author.last_name }}
							</h4>
						</div>
						<v-row>
							<v-col cols="12" md="2" class="image-author-wrapper">
								<div
									class="image-author"
									:style="'background-image: url(' + author.qzr_image.medium + ')'"
								/>
							</v-col>
							<v-col cols="12" offset-md="1" md="9">
								<div class="color-aili-purple font-size-25" v-html="author.description_tiny[0]" />
								<div class="color-aili-black font-size-20 mt-8" v-html="author.description_full[0]" />
							</v-col>
						</v-row>

						<Wave position="bottom" :color="getHexColor('aili-purple-light')" />
					</div>
					<div class="panel background-aili-white">
						<div v-for="(post, i) in posts" :key="i">
							<CardResultArticle
								:slug="post.slug"
								:preview_data="post.preview_data"
								:author="post.author_data"
								:url="post.relative_url"
								:image="post.featured_media_data"
								:title="post.title.rendered"
								:content="post.post_excerpt"
								:categories="post.category_data"
							/>
						</div>
					</div>
				</div>
			</MainColumn>
		</div>
	</div>
</template>

<script>
import Header from '@/components/ui/Header.vue';
import Wave from '@/components/ui/Wave.vue';
import CategoryHeaderArticles from '@/components/ui/CategoryHeaderArticles.vue';
import CardResultArticle from '@/components/ui/CardResultArticle.vue';

export default {
	name: 'SingleExpert',
	components: {
		Header,
		CategoryHeaderArticles,
		CardResultArticle,
		Wave,
	},
	metaInfo() {
		return this.formatYoastMeta(this.seo);
	},
	data() {
		return {
			author: null,
			post: null,
			blocks: null,
			posts: [],
		};
	},

	async mounted() {
		this.updateProperty({
			property: 'pageColor',
			value: 'aili-purple',
		});
		let response = await this.$api.get('qzr-data/authors/expert', {
			params: {
				slug: this.$route.params.slug,
			},
		});
		this.updateProperty({
			property: 'seo',
			value: response.data,
		});
		this.author = response.data;
		let response_last_posts = await this.$api.get('/wp/v2/posts', {
			params: {
				per_page: 100,
				'author[]': this.author.id,
			},
		});
		this.posts = response_last_posts.data;
	},
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 0;
  padding-bottom: $spacing-1;
}
.single-chef {
  min-height: 100vh;
  padding-bottom: $footer-distance;
}
.panel {
  position: relative;
  padding: $spacing-1;
  @media (max-width: $tablet-m) {
    padding: $spacing-0;
    padding-bottom: $spacing-1;
  }
}
.header-expert {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $spacing-1;
  @media (max-width: $tablet-m) {
    margin-bottom: $spacing-0;
  }
  .permalink {
    text-decoration: none;
    text-transform: uppercase;
  }
}

.image-author-wrapper {
  @media (max-width: $tablet-m) {
    display: flex;
    justify-content: center;
  }
}

.image-author {
  width: 200px;
  height: 200px;
  background-size: cover;
  border-radius: 100%;
}
</style>
